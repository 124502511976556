import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import  '../Sass/_window.scss';

const slideImages = [
    'img/window/slide_2.jpg',
    'img/window/slide_3.jpg',
    'img/window/slide_4.jpg'
];

function Window(){

    return (
        <div>
            <Slide easing="ease">
                <div className="each-slide">
                    <div style={{'backgroundImage': `url(${slideImages[0]})`}}>
                        <div>
                            <h1>Unique Christmas Decorations</h1>
                            <p>Shop a wide range of Christmas Decorations at Mandala today & fill your home with festive cheer. Order now for fast home delivery.
                            </p>
                            <a className='btn btn-primary' href='/homes-and-living/christmas'>Select and Buy</a>
                        </div>
                    </div>
                </div>
                <div className="each-slide">
                    <div style={{'backgroundImage': `url(${slideImages[1]})`}}>
                        <div>
                            <h2>Wrap up warm</h2>
                            <p>with a beautiful scarves</p>
                            <a className='btn btn-primary' href='/women/scarves'>Shop now</a>
                        </div>
                    </div>
                </div>
                <div className="each-slide">
                    <div style={{'backgroundImage': `url(${slideImages[2]})`}}>
                        <div>
                            <h2>20% off in selective items</h2>
                            <p>Don't miss. Shop now.</p>
                            <a className='btn btn-primary' href='/offers'>Shop now</a>
                        </div>
                    </div>
                </div>
            </Slide>
        </div>
    )
}


export default Window;