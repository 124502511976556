import React from 'react';
import {calDiscount, formatPrice, subTotal} from "../../Services/util";
import CartProduct from "../../Components/FloatCart/CartProduct";
import {connect, useDispatch} from 'react-redux';
import { loadCart, removeProduct } from '../../Services/cart/actions';
import { updateCart } from '../../Services/total/actions';
import Coupon from "../../Components/Form/Coupon";
import {clearCoupon} from "../../Actions/Actions";


function Checkout(props){

    const dispatch = useDispatch();

    const { cartTotal, cartProducts, removeProduct, coupon } = props;

    const products = cartProducts.map(p => {
        return (
            <CartProduct product={p} removeProduct={removeProduct} key={p.id} />
        );
    });

    function handleClearCoupon(){
        dispatch(clearCoupon());
    }



    return (
        <div className={'container checkout-page'}>
            <div className={'row'}>
                <div className={'col-sm-6 offset-sm-3'}>
                    <h1 className="text-center mt-3">Checkout <img src={'./img/shopping-cart-black.svg'} className={'cart-icon'} alt={'cart'}/></h1>
                        <div className={'row mt-3'}>
                            <div className={'col-12'}>
                                <h3 className={''}>Your Order</h3>
                                <div className={'card'}>
                                    <div className={'card-body'}>
                                {products}
                                {!products.length && (
                                    <p className="shelf-empty">
                                        Add some products in the cart <br />
                                        :)
                                    </p>
                                )}

                                <div className={'summary mt-3'}>
                                    {coupon.isValid && (
                                        <div>
                                        <div className={'left'}>Sub Total: </div>
                                        <div className={'right'}> {`${cartTotal.currencyFormat} ${formatPrice(cartTotal.totalPrice, cartTotal.currencyId
                                        )}`}</div>

                                        <div className={'left'}>Coupon Discount: <small className={'text-primary cursor-pointer'} onClick={handleClearCoupon}>(Remove)</small> </div>
                                        <div className={'right'}>- {`${cartTotal.currencyFormat} ${formatPrice(calDiscount(cartTotal.totalPrice,coupon.discount, coupon.type), cartTotal.currencyId
                                        )}`}</div>
                                        </div>
                                    )}


                                    {!!cartTotal.delivery && (
                                        <div>
                                            <div className={'left'}>Delivery: <small>Free if you order over £35.00</small> </div>
                                            <div className={'right'}>+ {`${cartTotal.currencyFormat} ${formatPrice(cartTotal.delivery, cartTotal.currencyId)}`}</div>
                                        </div>
                                    )}

                                    <div className={'left highlight'}>To Pay: </div>
                                    <div className={'right highlight'}> {`${cartTotal.currencyFormat} ${formatPrice(
                                        subTotal(cartTotal.totalPrice,calDiscount(cartTotal.totalPrice,coupon.discount, coupon.type)) + cartTotal.delivery
                                        ,
                                        cartTotal.currencyId
                                    )}`}</div>

                                </div>
                            </div>

                        </div>
                        </div>
                    </div>

                    {!coupon.isValid &&
                    <div className={'row mt-3 mb-3'}>
                        <div className={'col'}>
                            <Coupon/>
                        </div>
                    </div>}

                    {coupon.isValid &&
                    <div className={'row mt-3'}>
                        <div className={'col'}>
                            Congratulation! Coupon code <strong>{coupon.code}</strong> applied. <small className={'text-primary cursor-pointer'} onClick={handleClearCoupon}>(Remove)</small>
                        </div>
                    </div>
                    }

                    {!!products.length && (
                        <div className={'row mt-3 mb-5'}>
                            <div className={'col'}>
                                {/* checkout as guest */}
                                <a href={'/checkout/your-details'} className="btn btn-secondary btn-block">
                                    Continue
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    cartProducts: state.cart.products,
    newProduct: state.cart.productToAdd,
    productToRemove: state.cart.productToRemove,
    cartTotal: state.total.data,
    coupon: state.coupon
});

export default connect(
    mapStateToProps,
    { loadCart, updateCart, removeProduct, clearCoupon  }
)(Checkout);