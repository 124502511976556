import React, {useState} from 'react';
import {useParams,useHistory} from 'react-router-dom';
import API from "../../Services/api";



function ThankYou(){
    const {orderId} = useParams();
    const [data ,setData] = useState(null);

    const history = useHistory();

    API.get(`guest/order/${orderId}`).then(response => {
        setData(response.data.id);
    }).catch(error => {
        history.push('/');
    });


    return (
        <div className={'container checkout-page'}>
            <div className={'row mb-5'}>
                <div className={'col-sm-6 offset-sm-3'}>

                    <div className={'row'}>
                        <div className={'col'}>
                            <h1 className="text-center mt-3">Payment Successful</h1>
                        </div>
                    </div>

                    <div className={'row'}>
                        <div className={'col-4 offset-4'}>
                            <div className={'mt-3 text-center'}>
                                <img src={'/img/thumbs-up.svg'} width={'100%'} alt={'Payment Successful'}/>
                            </div>
                        </div>
                    </div>

                    <div className={'row'}>
                        <div className={'col'}>
                            <div className={'mt-3 text-center'}>
                                <h3 className={'mt-5'}>Thank you for your Order. </h3>
                                <p>We will send you a confirmation email shortly. For your record, your order reference number is <strong>{data}</strong>. </p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default ThankYou;