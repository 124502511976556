import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Header from "./Components/Layouts/Header";
import Footer from "./Components/Layouts/Footer";
import About from "./Views/About";
import Product from "./Views/Product";
import Products from "./Views/Products";
import Home from "./Views/Home";
import Pages from "./Views/Pages";
import CookieDisclaimer from "./Components/CookieDisclaimer";
import Error from "./Views/Error";
import FloatCart from "./Components/FloatCart";
import Checkout from "./Views/Checkout/Checkout";
import YourDetails from "./Views/Checkout/YourDetails";
import Payments from "./Views/Checkout/Payments";
import ThankYou from "./Views/Checkout/ThankYou";

function App (){

    const layout = {
        header: true,
        footer: true
    };

    return (
        <Router>
            <div id="maincontainer">
                <main>
                    <div className="App">
                        {layout.header ? <Header /> : ''}

                        <Switch>
                            <Route exact path="/" component={Home} />

                            <Route exact path="/all">
                                <Products primaryFilter={''}/>
                            </Route>

                            <Route exact path="/offers">
                                <Products primaryFilter={'offers'}/>
                            </Route>

                            <Route exact path="/women">
                                <Products primaryFilter={'women'}/>
                            </Route>

                            <Route exact path="/women/:filter">
                                <Products primaryFilter={'women'}/>
                            </Route>

                            <Route exact path="/men">
                                <Products primaryFilter={'men'}/>
                            </Route>

                            <Route exact path="/men/:filter">
                                <Products primaryFilter={'men'}/>
                            </Route>

                            <Route exact path="/kids-and-babies">
                                <Products primaryFilter={'kids-and-babies'}/>
                            </Route>

                            <Route exact path="/kids-and-babies/:filter">
                                <Products primaryFilter={'kids-and-babies'}/>
                            </Route>

                            <Route exact path="/boys">
                                <Products primaryFilter={'boys'}/>
                            </Route>

                            <Route exact path="/boys/:filter">
                                <Products primaryFilter={'boys'}/>
                            </Route>

                            <Route exact path="/girls">
                                <Products primaryFilter={'girls'}/>
                            </Route>

                            <Route exact path="/girls/:filter">
                                <Products primaryFilter={'girls'}/>
                            </Route>

                            <Route exact path="/homes-and-living">
                                <Products primaryFilter={'homes-and-living'}/>
                            </Route>

                            <Route exact path="/homes-and-living/:filter">
                                <Products primaryFilter={'homes-and-living'}/>
                            </Route>

                            <Route exact path="/jewellery-and-accessories">
                                <Products primaryFilter={'jewellery-and-accessories'}/>
                            </Route>

                            <Route exact path="/jewellery-and-accessories/:filter">
                                <Products primaryFilter={'jewellery-and-accessories'}/>
                            </Route>

                            <Route exact path="/shop/:id">
                                <Product/>
                            </Route>

                            <Route exact path={"/checkout"}>
                                <Checkout />
                            </Route>

                            <Route exact path={"/checkout/your-details"}>
                                <YourDetails />
                            </Route>

                            <Route exact path={"/checkout/payment"}>
                                <Payments />
                            </Route>

                            <Route exact path={"/checkout/payment/successful/:orderId"}>
                                <ThankYou />
                            </Route>


                            <Route path="/test">
                                <About />
                            </Route>
                            <Route exact path="/product">
                                <Products/>
                            </Route>


                            <Route exact path="/:url">
                                <Pages/>
                            </Route>

                            <Route status={404}>
                                <Error status={404} message='404 Page not found' />
                            </Route>
                        </Switch>
                    </div>
                </main>
            </div>
            {layout.footer ? <Footer /> : ''}
            <FloatCart />
            <CookieDisclaimer/>
        </Router>
    )

}



export default App;