import React from 'react';

function About(props) {
        return (
            <React.Fragment>
                <h1>About</h1>
                <p>This is about page</p>
            </React.Fragment>
        );


    }


export default About;