import {APPLY_COUPON, LOAD_CUSTOMER, UPDATE_CUSTOMER, CLEAR_COUPON} from "./ActionType";

export const loadCustomer = customer => ({
    type: LOAD_CUSTOMER,
    payload: customer
});

export const updateCustomer = customer => ({
    type: UPDATE_CUSTOMER,
    payload: customer
});

export const clearCoupon = () => {
    return {
        type : CLEAR_COUPON,
        payload : null
    }
}

export const applyCoupon = (coupon) => {
    return {
        type : APPLY_COUPON,
        payload : coupon
    }
}

export const increment = (number) => {
    return {
        type : 'INCREMENT',
        payload : number
    }
}

export const decrement = (number) => {
    return {
        type : 'DECREMENT',
        payload : number
    }
}

export const resetLayout = () => {
    return {
        type : 'NORMAL',
    }
}

export const blankLayout = () => {
    return {
        type : 'BLANK',
    }
}

export const showLoading = () => {
    return {
        type : 'LOADING',
    }
}

