import {UPDATE_CART} from './actionTypes';

export const updateCart = cartProducts => dispatch => {
    let productQuantity = cartProducts.reduce((sum, p) => {
        sum += p.quantity;
        return sum;
    }, 0);

    let totalPrice = cartProducts.reduce((sum, p) => {
        sum += p.price * p.quantity;
        return sum;
    }, 0);

    let discount =  cartProducts.reduce((sum, p) => {
        return 0;
    }, 0);


    let delivery = cartProducts.reduce((sum, p) => {
        if (totalPrice < parseFloat(process.env.REACT_APP_MINIMUM_ORDER) && productQuantity > 0) {
            //totalPrice += parseFloat(process.env.REACT_APP_DELIVERY);
            return parseFloat(process.env.REACT_APP_DELIVERY);
        } else {
            return 0;
        }
    }, 0);

    let payable =  cartProducts.reduce((sum, p) => {
        return (totalPrice - discount + delivery);
    }, 0);

    let cartTotal = {
        productQuantity,
        delivery,
        totalPrice,
        payable,
        discount,
        currencyId: 'GBP',
        currencyFormat: '£'
    };

    dispatch({
        type: UPDATE_CART,
        payload: cartTotal
    });
};
