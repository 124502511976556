import React from 'react';
import Window from "../Components/Window";
import SmallWindow from "../Components/SmallWindow";

function Home() {

    return (
        <div>
            <Window />

            <div className='container'>

                <div className='row mt-5 mb-3'>
                    <div className='col-sm-12 text-center'>
                        <h3 className='mt-3'>
                            We are here <br/>
                            to make your shopping experience easy.
                        </h3>
                        <p>We are online and high street store. Happy shopping with us.</p>
                    </div>
                </div>

                <div className='row mb-3'>
                <div className='col-sm-4 mt-5'>
                    <a href={'/men'}><SmallWindow src='/img/mens.jpg' title='Mens Clothing' subtitle='Keep yourself warm' /></a>
                </div>
                <div className='col-sm-4 mt-5'>
                    <a href={'/women'}><SmallWindow src='/img/woman.jpg'  title='Womens Clothing' subtitle='Creative designs' /></a>
                </div>
                <div className='col-sm-4 mt-5'>
                    <a href={'/kids-and-babies'}><SmallWindow src='/img/baby_2.jpg'  title='Kids and Babies' subtitle='Make them happy' /></a>
                </div>
            </div>

                <div className='row mb-3'>
                    <div className='col-sm-4 mt-5'>
                        <a href={'/homes-and-living/bedroom'}> <SmallWindow src='/img/beddings.jpg' title='Beddings' subtitle='Cosy and beautiful duvet covers' /></a>
                    </div>
                    <div className='col-sm-4 mt-5'>
                        <a href={'/homes-and-living'}>  <SmallWindow src='/img/cushions.jpg'  title='Home & Decoration' subtitle='Creative designs' /></a>
                    </div>
                    <div className='col-sm-4 mt-5'>
                        <a href={'/women/jewellery-and-accessories'}> <SmallWindow src='/img/accessories.jpg'  title='Accessories' subtitle='Make them happy' /></a>
                    </div>
                </div>

                <div className='row mb-3'>
                    <div className='col-sm-12 mt-5'>
                        <a href={'/offers'}>   <SmallWindow src='/img/clearances_2.jpg'  title='Clearances' subtitle='Get them with discounted price' height='300' /></a>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-sm-8 mt-5'>
                        <a href={'/men/hippie'}>  <SmallWindow src='/img/hippie.jpg'  title='Hippie' subtitle='These are hand made from Nepal and India'  /></a>
                    </div>

                    <div className='col-sm-4 mt-5'>
                        <a href={'/women/bags'}>  <SmallWindow src='/img/bag.jpg'  title='Bags' subtitle='Unique women bags' /></a>
                    </div>
                </div>

                <div className='row mt-5'>
                    <div className='col-sm-12'>
                    </div>
                </div>

            </div>
        </div>

    );
}

export default Home;