
const initialState = {
  header : true,
  footer : true,
  loading : false
};

const layoutReducer = (state = initialState , action) => {
    switch (action.type) {
        case 'NORMAL':
            return {
                header : true,
                footer : true
            };
        case 'BLANK' :
            return {
                header : false,
                footer : false
            };
        case 'HEADER_ONLY' :
            return {
                header : false,
                footer : true
            };
        case 'FOOTER_ONLY' :
            return {
                header : true,
                footer : false
            };
        case 'LOADING':
            let newState = state;
            newState.loading = true;
            return newState;
        default:
            return state;
    }
};

export default layoutReducer;