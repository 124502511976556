import React, {useEffect} from 'react';
import {useAxiosGet} from "../Hooks/HttpRequest";
import {useParams} from 'react-router-dom';
import Loading from "../Components/Layouts/Loading";
import Error from "./Error";

function Pages(props) {

    //gets all parameter url
    const {url} = useParams();
    const slug = `page/${url}`;

    let page = useAxiosGet(slug);

    useEffect(() => {
        if(page.data){
            document.title = page.data.title
        }else{
            document.title = 'Mandala'
        }


    }, [page]);

    let content = null

    if (page.error) {
        page.data.title = page.data.status + ' Error';
        content = <Error status={page.data.status} message={page.data.data.message} />
    }

    if (page.loading) {
        content = <Loading/>
    }

    if (page.data && !page.error) {
        content =
            <div
                dangerouslySetInnerHTML={{
                    __html: page.data.content
                }}>
            </div>

        if(page.data.wrap_container){
            content =  <div className='container'>
                {content}
            </div>
        }

    }

    return (
        <div>
            {content}
        </div>
    );


    }


export default Pages;