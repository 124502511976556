import React from 'react';
import {connect} from "react-redux";

function Footer(props){

    const {isLogged} = props;

    return (
        <footer>
            <div className="container">

                <div className="row padd-top-4">

                    <div className="col-sm-3 padd-top-2">
                        <b className="links-header">Mandala</b><br/>
                        <a href="/what-is-mandala">What is Mandala?</a> <br/>
                        <a href="/contact-us">Contact Us</a><br/>
                        <a href="/faq">Frequently Asked Questions</a> <br/>
                        <a href="/sitemap.xml">Site Map</a><br/>
                        <br/>
                        <b className="links-header">Privacy & Legal</b><br/>
                        <a href="/privacy">Cookies & Privacy Policy</a> <br/>
                        <a href="/terms-and-conditions">Terms & Conditions</a> <br/>
                    </div>


                    <div className="col-sm-3 padd-top-2">
                        <b className="links-header">Shortcuts</b><br/>
                        <a href="/">Home</a> <br/>
                        {isLogged && <a href="/my-account">My Account<br/></a>}
                        {isLogged && <a href="/my-order">My Order<br/></a>}
                        {isLogged && <a href="/my-payments">Payment<br/></a>}
                        <a href="/returns">Returns</a> <br/>
                        <a href="/Shipping">Shipping</a> <br/>
                        <a href="/offers">Clearances & Offers</a><br/>
                    </div>

                    <div className="col-sm-3 padd-top-2">
                        <b className="links-header">Shopping</b><br/>
                        <a href="/women">Women</a> <br/>
                        <a href="/men">Men</a> <br/>
                        <a href="/kids-and-babies">Kids and Babies</a> <br/>
                        <a href="/homes-and-living">Home and Livings</a> <br/>
                        <a href="/jewellery-and-accessories/jewellery">Jewellery</a> <br/>
                        <a href="/jewellery-and-accessories/accessories">Accessories</a> <br/>
                    </div>



                    <div className="col-sm-3 socialmedia padd-top-2 text-md-right">
                        <a href="https://www.facebook.com/MandalaStoreLimited"><img src="/img/fb.png" alt="Facebook"/></a>&nbsp;&nbsp;
                        <a href="https://twitter.com/MandalaStoreLtd"><img src="/img/tw.png" alt="Twitter"/></a>&nbsp;&nbsp;
                        <a href="https://www.instagram.com/mandalastorecraft7/"><img src="/img/in.png" alt="Instagram"/></a>&nbsp;&nbsp;
                        <br/>
                            <br/>
                                <p className="text-md-right">
                                    Email address:<br/>
                                    <a href="mailto:hello@mandala-store.co.uk">hello@mandala-store.co.uk</a><br/>
                                </p>
                    </div>
                </div>

            </div>

            <div className="text-right padd-1">
                <br/>
                    Copyright 2021© Mandala. Web design by <a
                    href="https://www.bzlinks.com">Bzlink Solution</a>.
            </div>

        </footer>
    )

}

const mapStateToProps = state => ({
    isLogged: state.isLogged
});


export default connect(
    mapStateToProps,
    {}
)(Footer);
