import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useParams} from "react-router-dom";
import axios from 'axios';
import ProductTab from "../Components/ProductTab";
import Slider from '@material-ui/core/Slider';
import withStyles from "@material-ui/core/styles/withStyles";

function Products(props) {


    const {filter} = useParams();

    const [query, setQuery] =useState('');
    const [pageNumber, setPageNumber] =useState(1);
    const [sort, setSort] = useState({'sortBy':'popular', 'direction' : 'asc'});
    const [priceRange, setPriceRange] = useState({'min':0, 'max':0});

    const [request, setRequest] = useState({
        loading: false,
        data: [],
        error: false
    })
    const [hasMore, setHasMore] = useState(false);

    const PriceSlider = withStyles({
        root: {
            color: '#52af77',
            height: 8,
        },
        thumb: {
            height: 24,
            width: 24,
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            marginTop: -8,
            marginLeft: -12,
            '&:focus, &:hover, &$active': {
                boxShadow: 'inherit',
            },
        },
        active: {},
        valueLabel: {
            left: 'calc(-50% + 4px)',
        },
        track: {
            height: 8,
            borderRadius: 4,
        },
        rail: {
            height: 8,
            borderRadius: 4,
        },
    })(Slider);


    function valuetext(value) {
        return `£${value}`;
    }

    const observer = useRef()
    const lastItemRef = useCallback(node =>{
        if (request.loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            console.log(entries[0].isIntersecting)
            if(entries[0].isIntersecting && hasMore){
                setPageNumber(prevPageNumber => prevPageNumber + 1)
            }
        })
        if (node) observer.current.observe(node)
    }, [request.loading, hasMore])

    let content = null;

    function handleSearch(e){
        setQuery(e.target.value);
        setPageNumber(1);
    }

    function handleSort(e){
        setSort(e.target.value);
        setPageNumber(1)
    }

    let timeout;
    function handlePrice(e, v){
        timeout && clearTimeout(timeout);
        timeout = setTimeout(() => {
            setPriceRange( {'min': v[0], 'max': v[1]});
            setPageNumber(1)
        }, 1000);

    }

    useEffect(() =>{
        setRequest({
            loading: false,
            data: [],
            error: false
        })
    }, [query, sort, priceRange])

    useEffect(()=>{
        if(!props.primaryFilter){
            document.title = 'Shop All';
        }else{
            document.title = 'Shop ' + props.primaryFilter + ' ' +((filter!==undefined)?filter:'');
        }

    },[props.primaryFilter, filter]);


    useEffect(() =>{
        const url = process.env.REACT_APP_API+'products';
        let cancel;
        let filters = [];

        if(props.primaryFilter !== undefined){
            filters.push(props.primaryFilter);
        }

        if(filter !== undefined){
            filters.push(filter);
        }

        axios({
            method: 'GET',
            url : url,
            params: {q:query, page: pageNumber,filters:filters, sort:sort, price_min : priceRange['min'], price_max: priceRange['max']},
            cancelToken: new axios.CancelToken(c => cancel = c)

        }).then(res => {
            setRequest(prevRequest => {
                return {
                    loading: false,
                    data: [...new Set([...prevRequest.data, ...res.data])],
                    error: false
                }
            })
            setHasMore(res.data.length > 0)
        }).catch(e => {
            if (axios.isCancel(e)) return
        })

        return () => cancel()

        },[query, pageNumber, filter, sort, priceRange, props.primaryFilter])

    if (request.data) {
        content =
                request.data.map((product, index) => {
                    if (request.data.length === index + 1) {
                       return  <li ref={lastItemRef} key={product.id} className='col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 mt-4'>
                            <ProductTab product={product}/>
                        </li>
                    } else {
                        return <li key={product.id} className='col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 mt-4'>
                            <ProductTab product={product}/>
                        </li>
                    }
                }
            )
    }
    return (
        <div>
            <div className='container'>
                <div className={'row'}>
                    <div className={'col-sm-3'}>
                        <div className={'row mt-4'}>
                            <div className={'col-12'}>
                                <div className="form-group">
                                <span>Filters</span>
                                <input type='text' className={'form-control'} value={query} onChange={handleSearch} placeholder={'Search'}></input>
                                </div>

                                <div className="form-group mt-2">
                                    <span>Sort</span>
                                    <select name={'sort'} className={'form-control'}  onChange={handleSort}>
                                        <option value='{"sortBy":"popular","direction":"asc"}'>Popular</option>
                                        <option value='{"sortBy":"price","direction":"asc"}'>Price: Low-High</option>
                                        <option value='{"sortBy":"price","direction":"desc"}'>Price: High-Low</option>
                                    </select>
                                </div>

                                <div className="form-group mt-2">
                                    <span>Price Range</span>
                                    <PriceSlider
                                    min={0}
                                    max={90}
                                    onChange={handlePrice}
                                    valueLabelDisplay="auto"
                                    aria-labelledby="range-slider"
                                    getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
                                    getAriaValueText={valuetext}
                                    defaultValue={[priceRange.min, priceRange.max]}
                                />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={'col-sm-9'}>
                        <ul className='row list-unstyled'>
                            {content}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Products;