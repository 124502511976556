export const formatPrice = (x, currency) => {
  switch (currency) {
    case 'BRL':
      return parseFloat(x).toFixed(2).replace('.', ',');
    default:
      return parseFloat(x).toFixed(2);
  }
};

export const subTotal = (amount,discount) => {
  let total = amount-discount;
  return (total >= 0) ? total : 0;
}

export const  calDiscount = (amount, discount, type) => {

  if(!type || type === null || type === undefined){
    return 0;
  }
  else if(type === 'amount'){
    return discount;
  }else if(type === 'percent'){
    return amount*discount/100;
  }else{
    return 0;
  }

}
