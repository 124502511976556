import React, {useState} from 'react';
import API from "../../Services/api";
import {connect, useDispatch} from "react-redux";
import {updateCart} from "../../Services/total/actions";
import {applyCoupon, clearCoupon} from "../../Actions/Actions";
import Loading from "../Layouts/Loading";

function Coupon(){

    const [state, setState] = useState({
        loading: false,
        code: null,
        errorMessage: null,
    });

    const dispatch = useDispatch();

    function handelChange(e){
        setState({
            ...state, code: e.target.value

        });
    }

    function handleApplyCoupon(){

        dispatch(clearCoupon());

        setState({
            loading: true,
            errorMessage: null,
            code: state.code
        });



        API.post('coupon/check', {
            'code': state.code,
        }).then(response => {
            if(response.data.isValid){
                let c = {
                        'code' : state.code,
                        'isValid' : true,
                        'discount' : response.data.discount,
                        'type': response.data.type,
                        'key': response.data.key
                }

                console.log(c);

                dispatch(applyCoupon(c));

                setState({
                    ...state, loading: false
                });

            }else{
                setState({
                    ...state,'errorMessage': 'Coupon Code is not valid'
                });
            }

        }).catch(error => {

            if(error.response){
                setState({
                    ...state,'errorMessage': error.response.data.errors.code
                });
            }else{
                setState({
                    ...state,'errorMessage': 'Something went wrong. This coupon code cannot be applied.'
                });
            }

        });
    }


    return (
        <div>
            {state.loading && <Loading/>}
            <small className={'d-block text-danger'}>{state.errorMessage}</small>
            <div className="input-group">
                <input type="text" className="form-control" placeholder="Coupon Code" aria-label="coupen" aria-describedby="basic-addon2" value={state.code} onChange={handelChange}  />
                <div className="input-group-append">
                    <button className="btn btn-primary" type="button" onClick={handleApplyCoupon}>Apply Coupon</button>
                </div>
            </div>
        </div>

    )
}

const mapStateToProps = state => ({
    cartTotal: state.total.data
});



export default connect(
    mapStateToProps,
    { updateCart, applyCoupon, clearCoupon }
)(Coupon);
