import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { formatPrice } from '../../../Services/util';
import Thumb from "../../Thumb";
import {Link} from "react-router-dom";

class CartProduct extends Component {
  static propTypes = {
    product: PropTypes.object.isRequired,
    removeProduct: PropTypes.func.isRequired
  };

  state = {
    isMouseOver: false
  };

  handleMouseOver = () => {
    this.setState({ isMouseOver: true });
  };

  handleMouseOut = () => {
    this.setState({ isMouseOver: false });
  };

  render() {
    const { product, removeProduct } = this.props;

    const classes = ['shelf-item'];

    if (!!this.state.isMouseOver) {
      classes.push('shelf-item--mouseover');
    }


    let removeContent = '';

    if(removeProduct){
        removeContent =  <div
            className="shelf-item__del"
            onMouseOver={() => this.handleMouseOver()}
            onMouseOut={() => this.handleMouseOut()}
            onClick={() => removeProduct(product)}
        />
    }

    return (
      <div className={classes.join(' ')}>
          {removeContent}
          <Thumb
          classes="shelf-item__thumb"
          src={product.image}
          alt={product.title}
        />
        <div className="shelf-item__details">
            <Link to={`/shop/${product.url}`}>
                <p className="title">{product.title}</p>
            </Link>
          <p className="desc">
            {`${product.variation?product.variation:''}`} <br />
            Quantity: {product.quantity}
          </p>
        </div>
        <div className="shelf-item__price">
          <p>{`${product.currencyFormat}  ${formatPrice(product.price)}`}</p>
        </div>
      </div>
    );
  }
}

export default CartProduct;
