import React, {useState} from "react";
import {useStripe, useElements, CardElement} from "@stripe/react-stripe-js";
import API from '../../Services/api';
import Loading from "../Layouts/Loading";
import {calDiscount, subTotal} from "../../Services/util";

function CardForm(props) {
    const stripe = useStripe();
    const elements = useElements();

    const [error, setError] = useState("");
    const [showLoading, setLoading] = useState(false);

    let errorContent = '';
    let loadingContent = '';


    async function handlePay() {
        setError("");
        setLoading(true);

        try {

            /*
            * billing_details :{
                    address : {
                        line1 : props.customer.address.address1,
                        line2 : props.customer.address.address2,
                        city : props.customer.address.town,
                        state : props.customer.address.county,
                        postal_code : props.customer.address.address1,
                    } ,
                    email : props.customer.email,
                    name : props.customer.fullname,
                    phone : props.customer.phone
                }
            *
            * */

            const {error, paymentMethod} = await stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardElement),
            });

            if (error) {
                setLoading(false);
                setError(error.message);
            } else {
                API.post('guest/place-order', {
                    'paymentMethod_id': paymentMethod.id,
                    'customer': props.customer,
                    'order': JSON.stringify(props.order),
                    'amount': props.amount.totalPrice,
                    'payable_amount':  subTotal(props.amount.totalPrice,calDiscount(props.amount.totalPrice,props.coupon.discount, props.coupon.type)) + props.amount.delivery,
                    'discount': calDiscount(props.amount.totalPrice,props.coupon.discount, props.coupon.type),
                    'delivery': props.amount.delivery,
                    'discount_code':  props.coupon.code,
                    'quantity': props.amount.productQuantity,
                }).then(response => {
                    console.log(response);
                    props.orderComplete(response.data.order_id);
                }).catch(error => {
                    setLoading(false);
                    setError(error.response.data.message);
                });

            }


            //console.log("[PaymentMethod]", paymentMethod);
        } catch (error) {
            console.log(error)
        }
    }

    if (error) {
        errorContent = <div className="alert alert-danger" role="alert">
            <small>{error}</small>
        </div>
    }

    if (showLoading) {
        loadingContent = <Loading/>
    }

    return (
        <div>
            {errorContent}
            <div className={'form-control form-big'}>
                <CardElement
                    options={{
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                                lineHeight: '30px'
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }}
                />
            </div>

            <div className={'row mt-3'}>
                <div className={'col'}>
                    <div onClick={handlePay} className="btn btn-secondary btn-block">
                        Pay Now
                    </div>
                </div>
            </div>

            {loadingContent}
        </div>
    );
}

export default CardForm;
