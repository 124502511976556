import {useState, useEffect} from 'react';
import axios from 'axios';

//hook are prefix with use
export function useAxiosGet(url){

    const [request, setRequest] = useState({
        loading: false,
        data: null,
        error: false
    })

    //monitor url and loads gets once
    useEffect(() => {
        setRequest({
            loading: true,
            data: null,
            error: false
        })
        axios.get(process.env.REACT_APP_API+url)
            .then(response => {
                setRequest(
                    {
                        loading: false,
                        data: response.data,
                        error: false
                    })
            })
            .catch((error) => {
                setRequest(
                    {
                        loading: false,
                        data: error.response,
                        error: true
                    })
            });
    }, [url])

    return request;
}