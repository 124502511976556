import {APPLY_COUPON, CLEAR_COUPON} from "../Actions/ActionType";

const initialState = {
        isValid: null,
        code : null,
        type : null,
        discount: null,
    }

const couponReducer = (state = initialState, action) => {
    switch(action.type){
        case APPLY_COUPON:
            return action.payload;
        case CLEAR_COUPON:
            return initialState;
        default:
            return state;
    }
}
export default couponReducer;