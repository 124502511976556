import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { loadCart, removeProduct } from '../../Services/cart/actions';
import { updateCart } from '../../Services/total/actions';
import CartProduct from './CartProduct';
import { formatPrice } from '../../Services/util';
import './style.scss';



class FloatCart extends Component {
    static propTypes = {
        loadCart: PropTypes.func.isRequired,
        updateCart: PropTypes.func.isRequired,
        cartProducts: PropTypes.array.isRequired,
        newProduct: PropTypes.object,
        removeProduct: PropTypes.func,
        productToRemove: PropTypes.object
    };

    state = {
        isOpen: false
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.newProduct !== this.props.newProduct) {
            this.addProduct(nextProps.newProduct);
        }

        if (nextProps.productToRemove !== this.props.productToRemove) {
            this.removeProduct(nextProps.productToRemove);
        }
    }

    openFloatCart = () => {
        this.setState({ isOpen: true });
    };

    closeFloatCart = () => {
        this.setState({ isOpen: false });
    };

    addProduct = product => {

        console.log(product);

        const { cartProducts, updateCart } = this.props;
        let productAlreadyInCart = false;

        cartProducts.forEach(cp => {
            if (cp.id === product.id) {
                cp.quantity += product.quantity;
                productAlreadyInCart = true;
            }
        });



        if (!productAlreadyInCart) {
            cartProducts.push(product);
        }

        //console.log(cartProducts);
        updateCart(cartProducts);
        this.openFloatCart();
    };

    removeProduct = product => {
        const { cartProducts, updateCart } = this.props;

        const index = cartProducts.findIndex(p => p.id === product.id);
        if (index >= 0) {
            cartProducts.splice(index, 1);
            updateCart(cartProducts);
        }
    };

    proceedToCheckout = () => {
        const {
            totalPrice,
            productQuantity,
            currencyFormat,
            currencyId
        } = this.props.cartTotal;

        if (!productQuantity) {
            alert('Add some product in the cart!');
        } else {
            alert(
                `Checkout - Subtotal: ${currencyFormat} ${formatPrice(
                    totalPrice,
                    currencyId
                )}`
            );
        }
    };

    render() {
        const { cartTotal, cartProducts, removeProduct } = this.props;

        const products = cartProducts.map(p => {
            return (
                <CartProduct product={p} removeProduct={removeProduct} key={p.id} />
            );
        });

        let classes = ['float-cart'];

        if (!!this.state.isOpen) {
            classes.push('float-cart--open');
        }

        return (
            <div className={classes.join(' ')}>
                {/* If cart open, show close (x) button */}
                {this.state.isOpen && (
                    <div
                        onClick={() => this.closeFloatCart()}
                        className="float-cart__close-btn"
                    >
                        <img src={'/img/iconfinder_icon-close_211652.svg'} width={'25px'} alt={'close'} />
                    </div>
                )}

                {/* If cart is closed, show bag with quantity of product and open cart action */}
                {!this.state.isOpen && (
                    <span
                        onClick={() => this.openFloatCart()}
                        className="bag bag--float-cart-closed"
                    >
            <span className="bag__quantity">{cartTotal.productQuantity}</span>
          </span>
                )}

                <div className="float-cart__content">
                    <div className="float-cart__header">
            <span className="bag">
              <span className="bag__quantity">{cartTotal.productQuantity}</span>
            </span>
                        <span className="header-title">Cart</span>
                    </div>

                    <div className="float-cart__shelf-container">
                        {products}
                        {!products.length && (
                            <p className="shelf-empty">
                                Add some products in the cart <br />
                                :)
                            </p>
                        )}
                    </div>

                    <div className="float-cart__footer">
                        <div className="sub">Sub Total</div>
                        <div className="sub-price">
                            <p className="sub-price__val">
                                {`${cartTotal.currencyFormat} ${formatPrice(
                                    cartTotal.totalPrice,
                                    cartTotal.currencyId
                                )}`}
                            </p>
                        </div>
                        <a href={'/checkout'} className="buy-btn btn-block">
                            Checkout
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    cartProducts: state.cart.products,
    newProduct: state.cart.productToAdd,
    productToRemove: state.cart.productToRemove,
    cartTotal: state.total.data
});

export default connect(
    mapStateToProps,
    { loadCart, updateCart, removeProduct }
)(FloatCart);
