import React, {useEffect, useState} from 'react';

function Address(props){


    const { onChange , value } = props;

    const [address, setAddress] = useState(value);
    const [manualAddressFlag, setManualAddressFlag] = useState(address.address1?true:false);
    const [state, setState] = useState({
        input: "",
        loading: false,
        data: [],
    });

    let selectAddress = '';
    let manualAddress = '';
    let addressSearch = '';
    let switchMethod = <small className={'text-secondary cursor-pointer'} onClick={(e)=>setManualAddressFlag(true)}>Manual Input</small>;

    function handleAddressChange(e){
        setAddress({
            ...address,[e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        onChange(address);
        // eslint-disable-next-line
    }, [address])




    if(manualAddressFlag){
        switchMethod = <small className={'text-secondary cursor-pointer'} onClick={(e)=>setManualAddressFlag(false)}>(Postcode Search)</small>;
    }else{
        switchMethod = <small className={'text-secondary cursor-pointer'} onClick={(e)=>setManualAddressFlag(true)}>(Manual Input)</small>;
    }

    if(!state.data.length && !manualAddressFlag){
        addressSearch  =  <div className={'search'}>
            <div className={'row'}>
                <div className={'col-sm-12 mt-2'}>
                    <input className={'form-control form-big'} placeholder={'Postcode'}   onBlur={(e)=>setState({'input': e.target.value, 'loading' : false, 'data':[]})}/>
                </div>
            </div>
            <div className={'row mt-2'}>
                <div className={'col-sm-6'}>
                    <button className={'btn btn-primary'} onClick={handleSearch}>Search</button>
                </div>
            </div>
        </div>
    }

    if(state.data.length && !manualAddressFlag){
        selectAddress =
            <div className={'select-address'}>
                <div className={'row mt-2'}>
                    <div className={'col-12'}>
                        <select className={'form-control form-big'} onChange={handleAddressSelect}>
                            <option>Select address</option>
                            {state.data.map((x,index)=>
                                <option value={index} key={index}>
                                    {x.summaryline}
                                </option>
                            )}
                        </select>
                    </div>
                </div>
            </div>
        }


    if(manualAddressFlag){
        manualAddress =  <div className={'select-address'}>
            <div className={'row mt-2'}>
                <div className={'col-12'}>
                    <input className={'form-control form-big'} placeholder={'Address1'} value={address.address1} name='address1' onChange={handleAddressChange}/>
                </div>
            </div>

            <div className={'row mt-2'}>
                <div className={'col-12'}>
                    <input className={'form-control form-big'} placeholder={'Address2 (optional)'} value={address.address2} name='address2' onChange={handleAddressChange} />
                </div>
            </div>

            <div className={'row mt-2'}>
                <div className={'col-6'}>
                    <input className={'form-control form-big'} placeholder={'Town'} value={address.town} name='town' onChange={handleAddressChange} />
                </div>
                <div className={'col-6'}>
                    <input className={'form-control form-big'} placeholder={'County'} value={address.county} name='county' onChange={handleAddressChange} />
                </div>
            </div>
            <div className={'row mt-2'}>
                <div className={'col-4'}>
                    <input className={'form-control form-big'} placeholder={'Postcode'} value={address.postcode} name='postcode' onChange={handleAddressChange} />
                </div>
            </div>
        </div>
    }

    function handleAddressSelect(e){
        let selectedAddress = state.data[e.target.value];

        if(selectedAddress === undefined){
            setAddress({
                'address1':'',
                'address2':'',
                'town': '',
                'county': '',
                'postcode':'',
            });
        }else{
            setManualAddressFlag(true);
            setAddress({
                'address1':selectedAddress.addressline1,
                'address2':selectedAddress.addressline2 + ((selectedAddress.addressline3 !== undefined) ? ', ' + selectedAddress.addressline3: ''),
                'town': selectedAddress.posttown,
                'county': selectedAddress.county,
                'postcode':selectedAddress.postcode,
            });
            setState({
                input: "",
                loading: false,
                data: [],
            })
        }

        onChange(address);
    }

    function handleSearch(e) {
        let { apiKey, identifier, country } = props;
        country = (country === "") ? (country = "UK") : country; // defaultProps only works if the prop is absent, not set to an empty string

        fetch(
            `https://ws.postcoder.com/pcw/${apiKey}/address/${country}/${encodeURIComponent(
                state.input
            )}?format=json&lines=2&identifier=${identifier}`
        )
            .then(response => {
                return response.json();
            })
            .then(
                data => {
                    setState({ data: data, loading: false });
                },
                error => {
                    alert('something went wrong');
                }
            );
    };


    return (
        <div className={'mt-3'}>
            <div className={'row'}>
                <div className={'col-12'}>
                    Address {switchMethod}
                </div>
            </div>

            {addressSearch}

            {selectAddress}

            {manualAddress}

        </div>

    )
}

Address.defaultProps = {
    apiKey: process.env.REACT_APP_POSTCODER,
    identifer: "react-address-lookup",
    country: "UK",
};

export default Address;
