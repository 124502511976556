import React from 'react';

function Error(props) {
        return (
            <div style={errorStyle}>
                <div>
                    <h1>{props.status} Error</h1>
                    <p>{props.message}</p>
                </div>
            </div>
        );

    }

    const errorStyle = {
    display: 'Flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
        height:'300px'
    }

export default Error;