import React from 'react';
import NavigationMenu from "./NavigationMenu";

function Navigation(){

    return (
            <NavigationMenu/>
    )

}

export default Navigation;
