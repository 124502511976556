import React from 'react';

import  '../Sass/_window.scss';


function SmallWindow(props){

    const smallWindowStyle ={
        'backgroundImage': `url(${props.src})`,
        'maxHeight' : props.height + 'px'
    }


    return (
        <div className='text-center'>
            <div className='small-window' style={smallWindowStyle}>
            </div>

            <h4 className='mt-3'>{props.title}</h4>
            <p>{props.subtitle}</p>

            <div className='btn btn-outline-primary'>Shop now</div>
        </div>
    )
}



export default SmallWindow;