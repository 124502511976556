import counterReducer from "./counter";
import loggedReducer from "./isLogged";
import layoutReducer from "./layout";
import {combineReducers} from "redux";
import cartReducer from './../Services/cart/reducer';
import totalReducer from './../Services/total/reducer';
import customerReducer from "./customer";
import couponReducer from "./coupon";

const allReducers = combineReducers({
    counter: counterReducer,
    isLogged: loggedReducer,
    layout: layoutReducer,
    cart: cartReducer,
    total: totalReducer,
    customer: customerReducer,
    coupon: couponReducer,
})

export default allReducers;