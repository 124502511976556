import React from 'react';
import {calDiscount, formatPrice, subTotal} from "../../Services/util";
import {connect} from 'react-redux';
import { loadCart, removeProduct, clearCart } from '../../Services/cart/actions';
import { updateCart } from '../../Services/total/actions';
import { useHistory } from 'react-router-dom';
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js/pure";
import CardForm from "../../Components/Form/CardForm";
import {clearCoupon} from "../../Actions/Actions";

const stripePromise = loadStripe(process.env.REACT_APP_STRIP_PUBLIC_KEY);

function Payments(props){

    const history = useHistory();
    const { cartProducts, clearCart, cartTotal, customer, updateCart, coupon, clearCoupon} = props;

    function orderComplete(orderId){
        clearCart();
        updateCart([]);
        clearCoupon();

        history.push(`/checkout/payment/successful/${orderId}`);
        }

    return (
        <div className={'container checkout-page'}>
            <div className={'row mb-5'}>
                <div className={'col-sm-6 offset-sm-3'}>
                    <h1 className="text-center mt-3">Payment <img src={'./../img/shopping-cart-black.svg'} className={'cart-icon'} alt={'Cart'}/></h1>

                    <div className={'mt-3'}>
                        <div className={'card card-body'}>
                            <strong>Postal Details</strong>
                            <div>
                                {customer.fullname}<br/>

                                {Object.values([customer.address.address1, customer.address.address2, customer.address.town, customer.address.county, customer.address.postcode]).filter(function(e){ return e;}).map(p =>{
                                    return (
                                        <span>{p}<br/></span>
                                        );
                                })}
                            </div>

                            <strong className={'mt-3'}>Contact Details</strong>
                            <div>
                                {customer.email}{customer.phone?<br/>:''}
                                {customer.phone}
                            </div>

                        </div>
                    </div>

                    <div className={'row mt-3'} style={amountStyle}>
                        <div className={'col-6 text-left'}>
                            Total To Pay:
                        </div>
                        <div className={'col-6 text-right'}>
                            {`${cartTotal.currencyFormat} ${formatPrice(
                                subTotal(cartTotal.totalPrice,calDiscount(cartTotal.totalPrice,coupon.discount, coupon.type)) + cartTotal.delivery
                                ,
                                cartTotal.currencyId
                            )}`}
                        </div>
                    </div>


                        <Elements stripe={stripePromise}>
                                <CardForm orderComplete={orderComplete} customer={customer} order={cartProducts} amount={cartTotal} coupon={coupon} />
                        </Elements>

                </div>
            </div>
        </div>
    )
}

const amountStyle = {
    'font-size':'2em'
}

const mapStateToProps = state => ({
    cartProducts: state.cart.products,
    cartTotal: state.total.data,
    customer : state.customer.customer,
    coupon : state.coupon
});

export default connect(
    mapStateToProps,
    { loadCart, clearCart, updateCart, removeProduct, clearCoupon }
)(Payments);