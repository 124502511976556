import React from 'react';
import {Link} from "react-router-dom";

function NavigationMenu(props){

    return (
            <ul className="navbar-nav mr-auto w-100">
                <li className="nav-item">
                    <a className="nav-link" href="/offers" onClick={props.closeMenu}>Offers</a>
                </li>
                <li className="nav-item">
                    <label className="nav-link" htmlFor="women">Women</label>
                    <input type="checkbox" id="women"/>
                    <div className='nav-dropdown'>
                        <div className='row'>
                            <div className='col-6'>
                                <ul>
                                    <li><a className="nav-link" href="/women/clothing" onClick={props.closeMenu}><b>Clothings</b></a></li>
                                    <li><a className="nav-link" href="/women/dress" onClick={props.closeMenu}>Dress</a></li>
                                    <li><a className="nav-link" href="/women/top-and-tshirt" onClick={props.closeMenu}>Top & T-shirt</a></li>
                                    <li><a className="nav-link" href="/women/jumpers" onClick={props.closeMenu}>Jumpers</a></li>
                                    <li><a className="nav-link" href="/women/knitwear" onClick={props.closeMenu}>Knitwear</a></li>
                                    <li><a className="nav-link" href="/women/jackets-and-coats" onClick={props.closeMenu}>Jackets & Coat</a></li>
                                    <li><a className="nav-link" href="/women/blazers" onClick={props.closeMenu}>Blazers</a></li>
                                    <li><a className="nav-link" href="/women/leggings" onClick={props.closeMenu}>Leggings</a></li>
                                    <li><a className="nav-link" href="/women/shirts-and-blouses" onClick={props.closeMenu}>Shirts & Blouses</a></li>
                                    <li><a className="nav-link" href="/women/jeans" onClick={props.closeMenu}>Jeans</a></li>
                                </ul>
                                <ul>
                                    <li><a className="nav-link" href="/women/footwear" onClick={props.closeMenu}><b>Footwear</b></a></li>
                                    <li><a className="nav-link" href="/women/shoes" onClick={props.closeMenu}>Shoes</a></li>
                                    <li><a className="nav-link" href="/women/slippers" onClick={props.closeMenu}>Slippers</a></li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <li><a className="nav-link" href="/women/jewellery-and-accessories" onClick={props.closeMenu}><b>Accessories</b></a></li>
                                    <li><a className="nav-link" href="/women/bags" onClick={props.closeMenu}>Bags</a></li>
                                    <li><a className="nav-link" href="/women/jewellery" onClick={props.closeMenu}>Jewellery</a></li>
                                    <li><a className="nav-link" href="/women/hats" onClick={props.closeMenu}>Hats</a></li>
                                    <li><a className="nav-link" href="/women/gloves" onClick={props.closeMenu}>Gloves</a></li>
                                    <li><a className="nav-link" href="/women/scarves" onClick={props.closeMenu}>Scarves</a></li>
                                    <li><a className="nav-link" href="/women/pashmina" onClick={props.closeMenu}>Pashmina</a></li>
                                </ul>

                                <ul>
                                    <li><a className="nav-link" href="/women/knitwear" onClick={props.closeMenu}><b>Knitwear</b></a></li>
                                    <li><a className="nav-link" href="/women/pyjamas" onClick={props.closeMenu}>Pyjamas</a></li>
                                    <li><a className="nav-link" href="/women/slippers" onClick={props.closeMenu}>Slippers</a></li>
                                </ul>
                                <ul>
                                    <li><a className="nav-link" href="/women/hippie" onClick={props.closeMenu}><b>Hippie</b></a></li>
                                    <li><a className="nav-link" href="/women/offers" onClick={props.closeMenu}><b>Offers</b></a></li>
                                    <li><a className="nav-link" href="/women" onClick={props.closeMenu}><b>All</b></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="nav-item">
                    <label className="nav-link" htmlFor="men">Men</label>
                    <input type="checkbox" id="men"/>
                    <div className='nav-dropdown'>
                        <div className='row'>
                            <div className='col-6'>
                                <ul>
                                    <li><a className="nav-link" href="/men/clothing" onClick={props.closeMenu}><b>Clothings</b></a></li>
                                    <li><a className="nav-link" href="/men/top-and-tshirt" onClick={props.closeMenu}>Top & T-shirt</a></li>
                                    <li><a className="nav-link" href="/men/jumpers" onClick={props.closeMenu}>Jumpers</a></li>
                                    <li><a className="nav-link" href="/men/knitwear" onClick={props.closeMenu}>Knitwear</a></li>
                                    <li><a className="nav-link" href="/men/jackets-and-coats" onClick={props.closeMenu}>Jackets & Coats</a></li>
                                    <li><a className="nav-link" href="/men/blazers" onClick={props.closeMenu}>Blazers</a></li>
                                    <li><a className="nav-link" href="/men/jeans" onClick={props.closeMenu}>Jeans</a></li>
                                    <li><a className="nav-link" href="/men/trousers" onClick={props.closeMenu}>Trousers</a></li>
                                </ul>
                                <ul>
                                    <li><a className="nav-link" href="/men/footwear" onClick={props.closeMenu}><b>Footwear</b></a></li>
                                    <li><a className="nav-link" href="/men/shoes" onClick={props.closeMenu}>Shoes</a></li>
                                    <li><a className="nav-link" href="/men/slippers" onClick={props.closeMenu}>Slippers</a></li>
                                </ul>
                            </div>
                            <div className='col-6'>
                                <ul>
                                    <li><a className="nav-link" href="/men/accessories" onClick={props.closeMenu}><b>Accessories</b></a></li>
                                    <li><a className="nav-link" href="/men/bags" onClick={props.closeMenu}>Bags</a></li>
                                    <li><a className="nav-link" href="/men/jewellery" onClick={props.closeMenu}>Jewellery</a></li>
                                    <li><a className="nav-link" href="/men/hats" onClick={props.closeMenu}>Hats</a></li>
                                    <li><a className="nav-link" href="/men/gloves" onClick={props.closeMenu}>Gloves</a></li>
                                    <li><a className="nav-link" href="/men/scarves" onClick={props.closeMenu}>Scarves</a></li>
                                </ul>
                                <ul>
                                    <li><a className="nav-link" href="/men/knitwear" onClick={props.closeMenu}><b>Knitwear</b></a></li>
                                    <li><a className="nav-link" href="/men/pyjamas" onClick={props.closeMenu}>Pyjamas</a></li>
                                    <li><a className="nav-link" href="/men/slippers" onClick={props.closeMenu}>Slippers</a></li>
                                </ul>
                                <ul>
                                    <li><a className="nav-link" href="/men/hippie" onClick={props.closeMenu}><b>Hippie</b></a></li>
                                    <li><a className="nav-link" href="/men/offers" onClick={props.closeMenu}><b>Offers</b></a></li>
                                    <li><a className="nav-link" href="/men" onClick={props.closeMenu}><b>All</b></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="nav-item">
                    <label className="nav-link" htmlFor="kids-and-babies">Kids & Babies</label>
                    <input type="checkbox" id="kids-and-babies"/>
                    <div className='nav-dropdown'>
                        <div className='row'>
                            <div className='col-4'>
                                <ul>
                                    <li><a className="nav-link" href="/kids-and-babies/clothing" onClick={props.closeMenu}><b>Clothings</b></a></li>
                                    <li><a className="nav-link" href="/kids-and-babies/top-and-tshirt" onClick={props.closeMenu}>Top & T-shirt</a></li>
                                    <li><a className="nav-link" href="/kids-and-babies/jumpers" onClick={props.closeMenu}>Jumpers</a></li>
                                    <li><a className="nav-link" href="/kids-and-babies/knitwear" onClick={props.closeMenu}>Knitwear</a></li>
                                    <li><a className="nav-link" href="/kids-and-babies/jackets-and-coats" onClick={props.closeMenu}>Jackets & Coat</a></li>
                                    <li><a className="nav-link" href="/kids-and-babies/trousers" onClick={props.closeMenu}>Trousers</a></li>
                                </ul>

                                <ul>
                                    <li><a className="nav-link" href="/kids-and-babies/footwear" onClick={props.closeMenu}><b>Footwear</b></a></li>
                                    <li><a className="nav-link" href="/kids-and-babies/shoes" onClick={props.closeMenu}>Shoes</a></li>
                                    <li><a className="nav-link" href="/kids-and-babies/slippers" onClick={props.closeMenu}>Slippers</a></li>
                                </ul>
                            </div>
                            <div className='col-4'>
                                <ul>
                                    <li><a className="nav-link" href="/kids-and-babies/accessories" onClick={props.closeMenu}><b>Accessories</b></a></li>
                                    <li><a className="nav-link" href="/kids-and-babies/bags" onClick={props.closeMenu}>Bags</a></li>
                                    <li><a className="nav-link" href="/kids-and-babies/jewellery" onClick={props.closeMenu}>Jewellery</a></li>
                                    <li><a className="nav-link" href="/kids-and-babies/hats" onClick={props.closeMenu}>Hats</a></li>
                                    <li><a className="nav-link" href="/kids-and-babies/gloves" onClick={props.closeMenu}>Gloves</a></li>
                                    <li><a className="nav-link" href="/kids-and-babies/scarves" onClick={props.closeMenu}>Scarves</a></li>
                                </ul>

                                <ul>
                                    <li><a className="nav-link" href="/kids-and-babies/knitwear" onClick={props.closeMenu}><b>Knitwear</b></a></li>
                                    <li><a className="nav-link" href="/kids-and-babies/pyjamas" onClick={props.closeMenu}>Pyjamas</a></li>
                                    <li><a className="nav-link" href="/kids-and-babies/slippers" onClick={props.closeMenu}>Slippers</a></li>
                                </ul>
                            </div>
                            <div className='col-4'>
                                <ul>
                                    <li><a className="nav-link" href="/girls" onClick={props.closeMenu}><b>Girls</b></a></li>
                                    <li><a className="nav-link" href="/girls/clothing" onClick={props.closeMenu}>Clothing</a></li>
                                    <li><a className="nav-link" href="/girls/shoes" onClick={props.closeMenu}>Shoes</a></li>
                                </ul>

                                <ul>
                                    <li><a className="nav-link" href="/boys" onClick={props.closeMenu}><b>Boys</b></a></li>
                                    <li><a className="nav-link" href="/boys/clothing" onClick={props.closeMenu}>Clothing</a></li>
                                    <li><a className="nav-link" href="/boys/shoes" onClick={props.closeMenu}>Shoes</a></li>
                                </ul>

                                <ul>
                                    <li><a className="nav-link" href="/kids-and-babies/bedroom" onClick={props.closeMenu}><b>Bedroom</b></a></li>
                                    <li><a className="nav-link" href="/kids-and-babies/offers" onClick={props.closeMenu}><b>Offers</b></a></li>
                                    <li><a className="nav-link" href="/kids-and-babies" onClick={props.closeMenu}><b>All</b></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="nav-item">
                    <label className="nav-link" htmlFor="homes-and-living">Home & Living</label>
                    <input type="checkbox" id="homes-and-living"/>
                    <div className='nav-dropdown'>
                        <div className='row'>
                            <div className='col-6'>
                                <ul>
                                    <li><a className="nav-link" href="/homes-and-living/home-furnishing" onClick={props.closeMenu}><b>Home Furnishing</b></a></li>
                                    <li><a className="nav-link" href="/homes-and-living/bedroom" onClick={props.closeMenu}>Bedroom</a></li>
                                    <li><a className="nav-link" href="/homes-and-living/bathroom" onClick={props.closeMenu}>Bathroom</a></li>
                                    <li><a className="nav-link" href="/homes-and-living/cushion" onClick={props.closeMenu}>Cushion</a></li>
                                    <li><a className="nav-link" href="/homes-and-living/mat" onClick={props.closeMenu}>Mat</a></li>
                                    <li><a className="nav-link" href="/homes-and-living/sheets" onClick={props.closeMenu}>Sheets</a></li>
                                    <li><a className="nav-link" href="/homes-and-living/curtains" onClick={props.closeMenu}>Curtains</a></li>
                                </ul>
                                <ul>
                                    <li><a className="nav-link" href="/homes-and-living/christmas" onClick={props.closeMenu}><b>Christmas</b></a></li>
                                    <li><a className="nav-link" href="/homes-and-living/tree-decoratives" onClick={props.closeMenu}>Tree Decoratives</a></li>
                                    <li><a className="nav-link" href="/homes-and-living/room-decoratives" onClick={props.closeMenu}>Room Decorations</a></li>
                                </ul>
                            </div>
                            <div className='col-6'>
                                <ul>
                                    <li><a className="nav-link" href="/homes-and-living/offers" onClick={props.closeMenu}><b>Offers</b></a></li>
                                    <li><a className="nav-link" href="/homes-and-living" onClick={props.closeMenu}><b>All</b></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="nav-item">
                    <label className="nav-link" htmlFor="jewellery-and-accessories">Jewellery & Accessories</label>
                    <input type="checkbox" id="jewellery-and-accessories"/>
                    <div className='nav-dropdown'>
                        <div className='row'>
                            <div className='col-6'>
                                <ul>
                                    <li><a className="nav-link" href="/jewellery-and-accessories/women" onClick={props.closeMenu}><b>Women</b></a></li>
                                    <li><a className="nav-link" href="/women/bags" onClick={props.closeMenu}>Bags</a></li>
                                    <li><a className="nav-link" href="/women/jewellery" onClick={props.closeMenu}>Jewellery</a></li>
                                    <li><a className="nav-link" href="/women/hats" onClick={props.closeMenu}>Hats</a></li>
                                    <li><a className="nav-link" href="/women/gloves" onClick={props.closeMenu}>Gloves</a></li>
                                    <li><a className="nav-link" href="/women/scarves" onClick={props.closeMenu}>Scarves</a></li>
                                    <li><a className="nav-link" href="/women/pashmina" onClick={props.closeMenu}>Pashmina</a></li>
                                </ul>
                            </div>
                            <div className='col-6'>
                                <ul>
                                    <li><a className="nav-link" href="/jewellery-and-accessories/men" onClick={props.closeMenu}><b>Men</b></a></li>
                                    <li><a className="nav-link" href="/men/bags" onClick={props.closeMenu}>Bags</a></li>
                                    <li><a className="nav-link" href="/men/jewellery" onClick={props.closeMenu}>Jewellery</a></li>
                                    <li><a className="nav-link" href="/men/hats" onClick={props.closeMenu}>Hats</a></li>
                                    <li><a className="nav-link" href="/men/gloves" onClick={props.closeMenu}>Gloves</a></li>
                                    <li><a className="nav-link" href="/men/scraves" onClick={props.closeMenu}>Scarves</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>


                <li className="mobile nav-item"><Link className="nav-link" to="/contact-us" >Contact us</Link></li>
                <li className="mobile nav-item"><Link className="nav-link" to="/faq" >FAQ</Link></li>
            </ul>
            )

}

export default NavigationMenu;
