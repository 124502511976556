import React, {useState} from 'react';
import Navigation from "./Navigation";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import useWindowDimensions from "../../Hooks/useWindowDimensions";

function Header(props){

    const {isLogged} = props;
    const [navigation, setNavigation] = useState(false);
    const {width} = useWindowDimensions();

    return (
        <header>

            <nav className="navbar navbar-expand-md">
                <div className="mob-nav-icon" id="mob-nav-icon" onClick={(e)=>setNavigation(!navigation)}>
                    <span className="cls"></span>
                    <span className="cls"></span>
                    <span className="cls"></span>
                </div>

                <a className="navbar-brand" href="/">
                    <img src="/img/logo-inline.svg" alt="Mandala" style={logoStyle}/>
                </a>

                {(navigation || width >=768) &&
                <div className="mainnav navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item">

                        </li>
                    </ul>

                    <ul className="no-mobile navbar-nav ml-auto mr-5">
                        {isLogged && <li className="nav-item"><Link className="nav-link" to="/my-account" >My Account</Link></li>}
                        <li className="nav-item"><Link className="nav-link" to="/contact-us" >Contact us</Link></li>
                        <li className="nav-item"><Link className="nav-link" to="/faq" >FAQ</Link></li>
                    </ul>
                </div>}
            </nav>

            <nav className="navbar navbar-expand-md">
                {(navigation || width >=768) && <Navigation/>}
            </nav>
        </header>
    )

}

const logoStyle ={
    maxWidth : '200px',
    width: '100%'
}

const mapStateToProps = state => ({
    isLogged: state.isLogged
});


export default connect(
    mapStateToProps,
    {}
)(Header);

