import {LOAD_CUSTOMER, UPDATE_CUSTOMER} from "./../Actions/ActionType";

const initialState = {
    customer: {
        'fullname':'',
        'phone':'',
        'email':'',
        'address':{
            'postcode':'',
            'address1':'',
            'address2': '',
            'town': '',
            'county': ''
        },
        'errors':''
    }
};

function customerReducer (state = initialState, action){
    switch (action.type) {
        case LOAD_CUSTOMER:
            return {
                ...state,
                customer: action.payload
            };
        case UPDATE_CUSTOMER:
            return {
                ...state,
                customer: Object.assign({}, action.payload)
            };
        default:
            return state;
    }
}

export default customerReducer;




