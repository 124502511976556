import React from 'react';

function Loading(){
    return (
        <div id="loading">
            <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </div>
        </div>
    )

}

export default Loading;
