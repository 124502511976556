import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import Loading from "../Components/Layouts/Loading";
import {useAxiosGet} from "../Hooks/HttpRequest";
import NumberFormat from "react-number-format";
import  '../Sass/_product.scss';
import ProductImageGallery from "../Components/ProductImageGallery";
import {useDispatch} from "react-redux";
import {addProduct} from "../Services/cart/actions";

function Product() {
    //gets all parameter url
    const {id} = useParams();
    const url = `products/${id}`;
    const [selectedVariation, setSelectedVariation] = useState([]);

    const dispatch = useDispatch();

    let product = useAxiosGet(url);

    let content = null;
    let selectVariationsLabel = [];
    let selectVariations = null;

    function cleanProduct(sv){
        let p = {
            id: sv.id,
            sku: sv.ref,
            url: product.data.url,
            title: product.data.title,
            image: product.data.images.length ? process.env.REACT_APP_PRODUCT_IMAGE_LOCATION+'thumbnail/'+ product.data.images[0].file : '/img/default-image.svg',
            description: product.data.description,
            variation: [sv.colour, sv.size, sv.sleeve, sv.neckline].filter(x=>{return x}).join(" | "),
            price: sv.price,
            currencyId: 'GBP',
            currencyFormat: '£',
            quantity: 1,
        }

        return p;
    }

    function handleSeletedVariationChange(e){
        setSelectedVariation(product.data.variations.filter(function(x){
            return x.ref ===  e.target.value;
        })[0]);
    }

    useEffect(() =>{
        if (product.data) {
            document.title = 'Shop '+ product.data.title;
            setSelectedVariation(product.data.variations[0]);
        }
    }, [product.data])

    if (product.data) {
       if(product.data.variations.length > 1){
           product.data.variations.forEach((k) => {
              for (const key in k.property){
                  console.log(key);
                  selectVariationsLabel.push(key);
              }
          });

          selectVariationsLabel = [...new Set(selectVariationsLabel)]; //remove duplicates

           let numOr0 = n => isNaN(n) ? 0 : n;

           selectVariations = !!(product.data.variations.reduce(function(sum, b) { return numOr0(sum) + numOr0(b.quantity)}, 0)) && <div className='col-sm-4' >
               <div className='form-group'>
                   <span>{selectVariationsLabel.map(i=>(i.charAt(0).toUpperCase() + i.slice(1))).join(" / ")}</span>
                   <select className='form-control' name='size' onChange={handleSeletedVariationChange}>
                       {product.data.variations.sort((a,b) => (a.quantity < b.quantity) ? 1 : ((a.quantity > b.quantity) ? -1 : 0)).map(x=>
                           <option value={x.ref} key={x.ref}  disabled={!x.quantity}>
                               {selectVariationsLabel.map(y=>
                                   x.property[y]
                               ).join(" / ")}
                           </option>
                       )}
                   </select>
               </div>
           </div>
       }else{
           selectVariations=<div className='col-sm-12' >
               <div className='form-group'>
                   {[selectedVariation.colour, selectedVariation.size, selectedVariation.sleeve, selectedVariation.neckline].filter(x=>{return x}).join(" / ")}

               </div>
           </div>
       }

        content =
            <div className='row mt-5 mb-5 product-specification'>
                <div className={'col-sm-4'}>
                   <ProductImageGallery images={product.data.images} selected={selectedVariation.main_image}/>
                </div>

                <div className={'col-sm-8'}>
                    <h1>{product.data.title}</h1>
                    <div>
                        <span className='product-subtitle'>{product.data.subtitle}</span><br/>
                        <span className='product-ref'>Ref: {selectedVariation.ref}</span>
                    </div>

                    <div className='row mt-3'>
                        <div className='col-sm-12'>
                                <span>Description</span>
                                <div className={'product-description light-text'}>
                                    {product.data.description}
                                </div>
                        </div>
                    </div>

                    <div className='row mt-3'>
                        <div className='col-sm-4'>
                            <div>
                                <span className={'h1'}><NumberFormat value={selectedVariation.price} displayType={'text'} prefix={'£'} decimalScale={2} fixedDecimalScale={true}/></span>
                                <span className={'product-price-original'}>{selectedVariation.offer && <NumberFormat value={selectedVariation.priceOriginal} displayType={'text'} prefix={'£'} decimalScale={2} fixedDecimalScale={true}/>}</span>
                                <span className={'product-price-offer'}>{selectedVariation.offer && selectedVariation.offer}</span>
                            </div>
                            <div className='form-group'>
                                <span>
                                    {!!selectedVariation.quantity && 'In stock'}
                                    {!selectedVariation.quantity && 'Out of stock'}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-3 '>
                        {selectVariations}
                    </div>

                    <div className='row mt-3'>
                        <div className='col-sm-4'>
                            {!selectedVariation.quantity && <button className='btn btn-disabled btn-block' data-sku={selectedVariation.ref}>Out of stock</button>}
                            {!!selectedVariation.quantity && <button className='btn btn-primary btn-block' onClick={()=> dispatch(addProduct(cleanProduct(selectedVariation)))} data-sku={selectedVariation.ref}>Add to cart</button>}
                        </div>
                    </div>
                </div>

            </div>
    }

    if (product.error) {
        content = product.data.message
    }

    if (product.loading) {
        content = <Loading/>
    }

    return (
        <div className={'container'}>
                {content}
        </div>
    );
}

export default Product;