import React, {useEffect, useState} from 'react';
import  '../Sass/_product.scss';


function ProductImageGallery(props){

    let content = null;

    const [mainImage, setMainImage] = useState({});

    function getMainImageStyle(img){
        const productTabStyle ={
            'backgroundImage': `url(${process.env.REACT_APP_PRODUCT_IMAGE_LOCATION+'extra-large/'+img})`
        }
        return productTabStyle;
    }

    function getThumbImageStyle(img){
        const productTabStyle ={
            'backgroundImage': `url(${process.env.REACT_APP_PRODUCT_IMAGE_LOCATION+'thumbnail/'+img})`
        };
        return productTabStyle;
    }

    function handleSetMainImage(e, img){

        let x = {
            'style': getMainImageStyle(img),
            'index' : e.currentTarget.dataset.id
        }
        setMainImage(x);
    }

    function handleNextMainImage(e){
        let next = 0;

        next = Number(e.currentTarget.dataset.selectedId)+1;

        //check position of image index
        //if last image
        if(next === props.images.length){
            next=0;
        }

      //setting style and index for main/selected image
        let x = {
            'style': getMainImageStyle(props.images[next].file),
            'index' : next
        }

        setMainImage(x);

    }

    useEffect(() =>{

        if (props.images.length) {

            let x = {
                'style': getMainImageStyle(props.images[0].file),
                'index' : 0
            }


            if(props.selected && props.images.find(e => e.file === props.selected)){
                x = {
                    'style': getMainImageStyle(props.images.find(e => e.file === props.selected).file),
                    'index' : props.images.findIndex(e => e.file === props.selected).file
                }
            }

            setMainImage(x);
        }
    }, [props]);

    if(!props.images.length){
        content=<div>
            <img src={'/img/default-image.svg'} width={'100%'}  alt="" />
        </div>
    }else{
        content=<div className={'product-gallery pr-4'}>

            <div className={'row gallery-window'} style={mainImage.style} data-selected-id={mainImage.index} onClick={handleNextMainImage} >
                <img src={'/img/aspect-ratio-2-3.png'} width={'100%'} alt={'2:3'}/>
            </div>

            <div className={'row gallery-thumbs'}>
                    {props.images.sort((a,b) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0)).map((image, index) => {
                    return <div key={index} data-image={image.file} className={'col-3 m-0 p-0 gallery-thumb'} style={getThumbImageStyle(image.file)} data-id={index} onClick={(e)=>handleSetMainImage(e, image.file)} >
                        <img src={'/img/aspect-ratio-1-1.png'} width={'100%'} alt={'1:1'} />
                    </div>
                    })}

            </div>

        </div>
    }

    return content;
}



export default ProductImageGallery;