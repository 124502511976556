import React from 'react';
import NumberFormat from "react-number-format";
import  '../Sass/_product.scss';
import {Link} from "react-router-dom";


function ProductTab(props){

    return (
        <Link to={`/shop/${props.product.url}`}>
        <div className='text-left product-tab'>
            <div className='product-tab-image'>
                <img src={props.product.image?process.env.REACT_APP_PRODUCT_IMAGE_LOCATION+props.product.image.file:'/img/default-image.svg'} alt=""/>
            </div>
            <div className='mt-3 product-title'>{props.product.title}</div>
            <div>
                <span className='product-price'><NumberFormat value={props.product.price} displayType={'text'} prefix={'£'} decimalScale={2} fixedDecimalScale={true}/></span>
                {props.product.offer && <span className='product-price-original'><NumberFormat value={props.product.priceOriginal} displayType={'text'} prefix={'£'} decimalScale={2} fixedDecimalScale={true}/></span>}
                {props.product.offer && <span className='product-price-offer'>({props.product.offer})</span>}
            </div>


        </div>
        </Link>
    )
}



export default ProductTab;