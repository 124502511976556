import React, {useState} from 'react';
import { connect } from 'react-redux';
import Address from "../../Components/Form/Address";
import {updateCustomer, loadCustomer} from "../../Actions/Actions";
import {useHistory} from "react-router-dom";

function YourDetails(props){
    let history = useHistory();
    const [customerDetails, setCustomerDetails] = useState(props.customer);

    function handleFormChange(e){
            setCustomerDetails({
                ...customerDetails,[e.target.name]: e.target.value
            });
    }

    function handleFormChangeAddress(value){
        setCustomerDetails({
            ...customerDetails,'address': value
        });
    }

    function handleClickContinue(e){
        if(validate()){
            const {updateCustomer} = props;
            updateCustomer(customerDetails);
            console.log(customerDetails);

            history.push('/checkout/payment');
        }
    }

    function validate(){
        let input = customerDetails;
        let errors = {};
        let isValid = true;

        if(!input['fullname']){
            isValid = false;
            errors["fullname"] = "Please enter your fullname";
        }

        if (!input["email"]) {
            isValid = false;
            errors["email"] = "Please enter your email Address.";
        }

        if (typeof input["email"] !== "undefined") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(input["email"])) {
                isValid = false;
                errors["email"] = "Please enter a valid email address.";
            }
        }

        if (!input["address"]["address1"] || !input["address"]["postcode"] ) {
            isValid = false;
            errors["address"] = "Please provide a valid address.";
        }

        //not sure why we need this
        input.errors = errors;

        //console.log(c);
        setCustomerDetails({
            ...customerDetails,'errors': errors
        });


        return isValid;
    }



    return (
        <div className={'container checkout-page'}>
            <div className={'row'}>
                <div className={'col-sm-6 offset-sm-3'}>
                    <h1 className="text-center mt-3">Checkout <img src={'./../img/shopping-cart-black.svg'} alt='cart-icon' className={'cart-icon'}/></h1>

                    <div className={'row mt-3'}>
                        <div className={'col'}>
                            <h3 className={''}>Your Details</h3>

                            <label htmlFor="fullname" className="col-form-label mt-2">Fullname*</label>
                            <input id="fullname" type="text" className="form-control form-big" name="fullname" value={customerDetails.fullname} onChange={handleFormChange} required autoComplete="Fullname"/>
                            <small className={'d-block text-danger'}>{customerDetails.errors?customerDetails.errors.fullname:''}</small>

                            <label htmlFor="phonenumber" className="col-form-label mt-2">Phone Number</label>
                            <input id="phonenumber" type="text" className="form-control form-big" name="phone" value={customerDetails.phone} onChange={handleFormChange} required autoComplete="Phone number"/>
                            <small className={'d-block text-danger'}>{customerDetails.errors?customerDetails.errors.phone:''}</small>

                            <label htmlFor="email" className="col-form-label mt-2">E-Mail Address*</label>
                            <input id="email" type="email" className="form-control form-big" name="email" value={customerDetails.email} onChange={handleFormChange} required autoComplete="email"/>
                            <small className={'d-block text-danger'}>{customerDetails.errors?customerDetails.errors.email:''}</small>

                            <Address value={customerDetails.address} onChange={handleFormChangeAddress}/>
                            <small className={'d-block text-danger'}>{customerDetails.errors?customerDetails.errors.address:''}</small>

                        </div>
                    </div>
                    <div className={'row mt-5 mb-5'}>
                        <div className={'col'}>
                            <button  className="btn btn-secondary btn-block" onClick={handleClickContinue}>
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => ({
    customer:state.customer.customer,
    updateCustomer: state.customer.updateCustomer
});

export default connect(
    mapStateToProps,
    { loadCustomer, updateCustomer }
)(YourDetails);