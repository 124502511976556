import React, {useState} from 'react';
import {setCookie, getCookie} from '../Helpers/Cookies';


function CookieDisclaimer(){

    const [disclaimer,setDisclaimer] = useState(getCookie('mandala-cookie-disclaimer'));

    const accept = () =>{
        setCookie('mandala-cookie-disclaimer',true, 7)
        setDisclaimer(getCookie('mandala-cookie-disclaimer'));
    }


    let content = (<div id="cookielaw" style={elementStyle}>
        <div className="cookielaw_container"> We use cookies to ensure that you have the best experience on our
            website. If you continue browsing, we’ll assume that you are happy to receive all our cookies. You can
            change your cookie settings at any time. &nbsp;&nbsp;<span onClick={accept}>Accept</span></div>
    </div>)


    return (
        <div>
            {disclaimer ? '': content}
        </div>
    )
}

const elementStyle = {
    display: 'flex'
}


export default CookieDisclaimer